$ = jQuery;

$(document).ready(function(){
	// Initialize slick slider
	// Slick slider options have responsive option with destroys - unslicks - slider on 992px
	// Unslick emits destroy event
	// We listen to this event and re-initiate (re-slick) our slider, once window is resized back to normal
	// We use bounce function so that our code doesn't fire hundreds of times when window is resized
	// Source: https://engineering.growella.com/reinit-slick-carousel/
	$('.card-deck').on('destroy', function (ev, slick) {
		let carousel = $(this);
		let reinit = debounce(function () {
			/*
			* slick.activeBreakpoint will tell us the breakpoint
			* at which the carousel was destroyed.
			*/
			if (slick.activeBreakpoint < window.innerWidth) {
				//return;
			}

			// Re-initialize with the old settings.
			carousel.slick(slick.options);
			// carousel.slick('setPosition');

			// Remove this event listener.
			window.removeEventListener('resize', reinit);
		}, 500);

		// Assign our debounced callback to window.resize.
		window.addEventListener('resize', reinit);
	}).slick({
		slidesToScroll: 1,
		infinite: false,
		slidesToShow: 3,
		prevArrow: '<button type="button" class="slick-prev slick-arrow position-absolute bg-transparent py-0 px-0 border-0 text-secondary" aria-label="Go to previous slide"><span class="far fa-chevron-left"></span></button>',
		nextArrow: '<button type="button" class="slick-next slick-arrow position-absolute bg-transparent py-0 px-0 border-0 text-secondary" aria-label="Go to next slide"><span class="far fa-chevron-right"></span></button>',
		responsive: [
			// {
			// 	breakpoint: 4000,
			// 	settings: {
			// 		slidesToShow: 3
			// 	}
			// },
			{
				breakpoint: 992,
				settings: 'unslick'
			},
		]
	});

});

$(window).on('load resize', function(){
	//Fix slider size on resize of the screen
	if ($('.card-deck.slick-initialized')) {
		$('.card-deck.slick-initialized').slick('resize');
	}
});
// $('.card-deck').on('init', function(){
	//  $(window).resize();
// });
// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
// Source: https://davidwalsh.name/javascript-debounce-function
function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};